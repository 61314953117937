import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import PropTypes from "prop-types";
import ReviewCard from "../../TestimonialReviews/ReviewCard";
import BigReviewStyles from "./BigReviewStyles";
import useWindowSize from "../../../hooks/use-window-size";

const CARD_OFFSET = 100;

function moved(array, from, to, on = 1) {
  return (
    // eslint-disable-next-line no-param-reassign
    (array = array.slice()),
    array.splice(to, 0, ...array.splice(from, on)),
    array
  );
}

const Vertical = ({ reviews }) => {
  const [cards, setCards] = React.useState(reviews);
  const size = useWindowSize();
  const isMobile = size.width < 1025;
  const SCALE_FACTOR = isMobile ? 0.01 : 0.1;

  const moveToEnd = (from) => {
    setCards(moved(cards, from, from - 1.5, cards.length - from));
  };

  return (
    <div className="custom-car-wrapper">
      <ul className="custom-car-wrap">
        {cards.map((review, index) => {
          const canDrag = index === Math.floor((cards.length - 1) / 2);
          const middle = Math.floor((cards.length - 1) / 2);
          const init = isMobile ? 0.9 : 1.1;
          return (
            <motion.li
              key={review.quote}
              className="custom-car-slide"
              styles={{
                backgroundColor: "#fff",
              }}
              animate={{
                top: !isMobile && index * -CARD_OFFSET,
                left: isMobile && index * CARD_OFFSET,
                // eslint-disable-next-line no-nested-ternary
                scale: !canDrag
                  ? index > middle
                    ? init - index * SCALE_FACTOR
                    : 0.9 - index * SCALE_FACTOR
                  : 1,
                zIndex: !canDrag ? cards.length - index : 9,
              }}
              drag={canDrag && !isMobile ? "y" : "x"}
              dragConstraints={{
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
              }}
              onDragEnd={() => moveToEnd(index)}
            >
              <ReviewCard
                key={review.quote}
                quote={review.quote}
                reviewDate={review.reviewDate}
                reviewer={review.reviewer}
                reviewerBio={review.reviewerBio}
                rating={review.rating}
                reviewerPhoto={review.reviewerPhoto}
              />
            </motion.li>
          );
        })}
      </ul>
      <BigReviewStyles />
    </div>
  );
};

Vertical.propTypes = {
  reviews: PropTypes.arrayOf(PropTypes.any),
};
Vertical.defaultProps = {
  reviews: [],
};
export default Vertical;
