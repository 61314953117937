import React from "react";
import { viewports, colors } from "../../../style-vars";

const { xsOnly, smOrSmaller, mdOrSmaller, lgOrSmaller } = viewports;
const { white, black } = colors;

const BigReviewStyles = () => (
  <style jsx>{`
    .big-review {
      position: relative;
      padding: 100px 57px;
      background: ${colors.white} !important;
    }
    .big-review .horizontal-card {
      transform: scale(0.7);
    }
    .big-review .slick-current {
      transform: scale(1.4) !important;
    }
    .big-card {
      background: #f3f4f6;
      border-radius: 32px;
      min-height: 500px;
    }
    .custom-car-slide {
      position: absolute;
      width: 450px;
      height: 220px;
      border-radius: 8px;
      transform-origin: top center;
      list-style: none;
      cursor: grab;
    }
    .custom-car-wrap {
      position: relative;
      width: 450px;
      height: 220px;
      padding: 16px;
    }
    .custom-car-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(60%);
      height: 100%;
      z-index: 5;
    }
    @media ${lgOrSmaller} {
      .big-review {
        position: relative;
        padding: 100px 0;
        width: 100%;
        background: ${colors.white} !important;
      }
      .big-card {
        background: #f3f4f6;
        border-radius: 32px;
        min-height: 500px;
        padding-bottom: 200px;
      }
      .custom-car-wrapper {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        transform: translateY(0%) translateX(-50%);
        left: 25%;
        height: 100%;
        width: 100%;
        z-index: 5;
      }
      custom-car-wrap {
        position: relative;
        width: 100vw !important;
        height: 220px;
        padding: 64px;
      }
      .custom-car-slide {
        position: absolute;
        width: 100%;
        height: 220px;
        border-radius: 8px;
        transform-origin: top center;
        list-style: none;
        cursor: grab;
      }
    }

    @media ${mdOrSmaller} {
    }
    @media ${smOrSmaller} {
      .big-review {
        position: relative;
        padding: 60px 0;
        background: ${colors.white} !important;
        margin-bottom: 32px;
      }
      .big-card {
        padding-bottom: 0;
      }
      .big-custom {
        margin-top: 160px;
      }
      .big-custom img {
        object-position: top left;
      }
      .review-heading {
        padding: 80px 24px 0 24px;
      }
      .custom-car-wrapper {
        transform: translateY(0%) translateX(-60%);
        left: auto;
      }
    }
  `}</style>
);

export default BigReviewStyles;
