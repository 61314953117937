import React from "react";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import Image from "../../ImageQuerys/RandomImages";
import BigReviewStyles from "./BigReviewStyles";
import Vertical from "./CustomCarousel";

const BigReviews = (props) => {
  const { sectionData, bgDark } = props;
  return (
    <section
      className={`big-review grid grid-cols-1 max-w-1440 w-full border-box ${
        bgDark ? "bg-black" : "bg-white"
      }`}
    >
      <div className="grid grid-cols-1 xl:grid-cols-2 bg-black-800 px-10 py-[80px] lg:p-[90px] rounded-t-large">
        <div>
          <h2 className="text-[42px] text-h1-small lg:text-h1 font-bold whitespace-normal p-0 mb-10 ">
            {sectionData.bigTitle}
            <span className="text-primary">.</span>
          </h2>
          <p className="text-p text-black-200 ">{sectionData.bigSub}</p>
          {/* <CtaSecondary
              ctaTitle={sectionData.linkWord}
              target={sectionData.target}
            /> */}
        </div>
        <div className="special-padding">
          <Vertical reviews={sectionData.reviews} />
        </div>
      </div>
      <div className="rounded-b-large">
        <Image
          imageName="big-review-bg.png"
          className="big-custom rounded-b-large h-40"
        />
      </div>
      <BigReviewStyles />
    </section>
  );
};

BigReviews.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
  bgDark: PropTypes.bool,
};
BigReviews.defaultProps = {
  sectionData: {},
  bgDark: true,
};

export default BigReviews;
